<template>
  <div class="d-flex flex-column flex-root">
    <div
            class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
            id="kt_login"
    >
      <!--begin::Aside-->
      <div
              class="login-aside d-flex flex-column align-items-center justify-content-center"
              :style="{ backgroundImage: `url(${backgroundImage})`, bgColor }"
      >
        <div class="overlay-bg" :style="{backgroundColor: bgColor}"></div>
        <div class="d-flex flex-column-auto flex-column pt-lg-30 pt-15">
          <p v-if="authLoading">
            <v-skeleton-loader class="max-h-70px"
                               type="image"
            ></v-skeleton-loader>
          </p>
          <p class="text-center mb-10" v-else>
            <a href="#" class="text-center">
              <img
                      :src="app_logo_path"
                      class="max-h-70px"
                      alt="College"
                      v-if='app_logo_path!=null'
              />

            </a>
          <h3 v-if="setting"
              class="font-weight-bolder text-center font-size-h4 font-size-h1-lg"
              :style="collegeColor"
          >
            {{setting.college_name || ''}}
          </h3>
          <h3
                  class="font-weight-bolder text-center font-size-h5"
                  :style="sloganColor"
          >
            {{setting.slogan || ''}}
          </h3>
          </p>

        </div>
      </div>
      <!--begin::Aside-->

      <!--begin::Content-->
      <div
              class="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden"
      >
        <div class="d-flex flex-column-fluid flex-center mt-lg-30 mt-lg-0">
          <router-view></router-view>
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->

<script>
  import {mapState} from "vuex";
  import {ColorsVariableMixin} from "@/mixins/ColorsVariableMixin";


  export default {
    name: "auth",
    mixins:[ColorsVariableMixin],

    data() {
      return {

      }
    },
    methods: {

    },
    mounted() {
      this.getColors();
    },
    computed: {
      ...mapState({
        errors: state => state.auth.errors
      }),
      backgroundImage() {

        if (this.login_bg_path) {
          return this.login_bg_path;
        }
        return process.env.BASE_URL + "/";
      },
      bgColor() {

        if (this.login_bg_color) {
          return "background-color:" + this.login_bg_color;
        }
        return "background-color: #000000";
      },
      collegeColor() {
        if (this.college_color) {
          return "color:" + this.college_color;
        }
        return "color: #FFFFFF";
      },
      sloganColor() {
        if (this.slogan_color) {
          return "color:" + this.slogan_color;
        }
        return "color: #FFFFFF";
      }
    }
  };
</script>

<style lang="scss" scope>
  .login-aside {
    position: relative;
    z-index: 1;
    background-size: cover;
    /*flex: 1.5 !important;*/

    & > * {
      position: relative;
      z-index: 3;
    }

    .overlay-bg {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      opacity: 0.75;
      background-color: var(--login-bg-color);
    }

    & + * {
      flex: 2 !important;
    }
  }
</style>
